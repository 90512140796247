<template>
  <div>
    <div class="py-1">
      <v-data-table
          class="elevation-2 mt-2"
          :headers="headers"
          :items="searchResults"
          dense
          v-if="relatedClientMonitoringPlans.length >=1"
          hide-default-footer
      >
        <template v-slot:item.actions="{ item }">
          <!-- Custom content for the "Actions" column -->
          <!--            <v-btn color="chips" small class="py-0 my-0" link dense @click="syncronizeDrawer(item)">-->
          <!--              <v-icon color="white" class="mr-2">mdi mdi-eye</v-icon>-->
          <!--              <v-list-item-content class="font-bold" style="font-size: 14px; color:white;">{{ $t('view') }}</v-list-item-content>-->
          <!--            </v-btn>-->
          <v-btn color="chips" x-small class="py-0 my-0" link dense @click="syncronizeEditDrawer(item)">
            <v-icon small  color="white" class="mr-2">mdi mdi-pencil</v-icon>
            <v-list-item-content class="font-bold" style="font-size: 12px; color:white;">{{ $t('edit') }}</v-list-item-content>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-btn @click="syncronizeDrawers" v-if="relatedClientMonitoringPlans <=0" class="float-right mt-3 text-color-white" color="primary2">
      + {{ $t('add-monitoring-plan') }}
    </v-btn>
    <v-dialog  v-model="showConfirmationDialog" max-width="500">
      <v-card>
        <v-card-title style="background-color:#19003F; color:white;" >Confirm Deletion</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-icon class="my-5" color="error" size="40">mdi-alert</v-icon>
          </v-row>
          <h6 class="text-center">Are you sure you want to delete the monitoring plan? This action cannot be undone.</h6>
        </v-card-text>
        <v-card-actions class="ml-auto">
          <v-btn color="secondary" style="color:black;" @click="cancelAction">Cancel</v-btn>
          <v-btn color="primary2" style="color:white;" @click="confirmDeletion()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data () {
    return {
      dataForDrawer: [],
      getItem: {},
      showConfirmationDialog: false,
    };
  },
  computed: {
    ...mapState({
      relatedClientMonitoringPlans: state => state.treatmentplan.relatedClientMonitoringPlans,
    }),
    ...mapGetters({
      appBarSearch: 'filterbar/getSearch',
    }),
    searchResults () {
      const searchTerm = this.appBarSearch ? this.appBarSearch.toLowerCase() : '';
      return this.relatedClientMonitoringPlans.map(item => ({
        ...item,
        started: this.formatDate(item.started),
      })).filter(item => {
        const tpName = item.tpName && item.tpName ? item.tpName.toLowerCase() : '';
        const tpDescription = item.tpDescription && item.tpDescription ? item.tpDescription.toLowerCase() : '';

        return tpName.includes(searchTerm) || tpDescription.includes(searchTerm);
      });
    },
    headers () {
      const headers = [
        { text: this.$t('name'), value: 'tpName', align: 'start pl-3' },
        { text: this.$t('actions'), value: 'actions', align: 'end pr-3' },
      ];
      return headers;
    },
  },
  async mounted () {
    this.getRelatedClientMonitoringPlans();
  },
  methods: {
    syncronizeDrawer (item) {
      // Pass the item as a second argument to the mutation
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', item);
    },
    syncronizeEditDrawer (item) {
      // Pass the item as a second argument to the mutation
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', { item, updateDrawer: true, page2: 'monitoring' });
    },
    syncronizeDrawers (item) {
      const parameters = {
        detailsDrawer: true,
        page: this.$route.name.toLowerCase(),
        item: item,
      };
      this.$store.commit('SET_DETAILS_DRAWER', parameters);
    },
    getRelatedClientMonitoringPlans () {
      this.$store.dispatch('treatmentplan/relatedClientMonitoringPlans');
    },
    deleteMonitoring (item) {
      this.getItem = item;
      this.showConfirmationDialog = true;
    },
    cancelAction () {
      this.showConfirmationDialog = false;
    },
    confirmDeletion () {
      const body = {
        id: this.getItem.id,
      };
      this.$store.dispatch('treatmentplan/deleteMonitoringPlan', body).then(res => {
        this.relatedClientMonitoringPlans();
        this.showConfirmationDialog = false;
      });
    },
    formatDate (dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
  watch: {},
};
</script>

<style scoped>

</style>
